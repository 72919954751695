<template>
  <div>
    <section class="section is-main-section">
      <card-component :title="$route.params.id ? `Çekim Talebi Düzenle` : 'Çekim Talebi Oluştur'" icon="cash-multiple">
        <form @submit.prevent="save">
          <b-field horizontal label="Site" v-if="!$store.state.user.siteId">
            <b-select size="is-small" v-model="form.siteId">
              <option :value="site.uuid" v-for="site in sites">{{ site.name }}</option>
            </b-select>
          </b-field>
          <b-field horizontal label="Ücret">
            <b-input size="is-small" v-model="form.total" placeholder="Ücret" name="total" required/>
          </b-field>
          <b-field horizontal label="Kişi Adı">
            <b-input size="is-small" v-model="form.name" placeholder="Kişi adı" name="userName" required/>
          </b-field>
          <b-field horizontal label="Kullanıcı ID">
            <b-input size="is-small" v-model="form.userId" placeholder="Kullanıcı ID" name="userId" required/>
          </b-field>
          <b-field horizontal label="Kullanıcı Adı">
            <b-input size="is-small" v-model="form.username" placeholder="Kullanıcı Adı" name="username" required/>
          </b-field>
          <b-field horizontal label="İşlem ID">
            <b-input size="is-small" v-model="form.processId" placeholder="İşlem ID" name="processId" required/>
          </b-field>
          <b-field horizontal label="Hesap Adı">
            <b-input size="is-small" v-model="form.accountName" placeholder="Hesap Adı" name="accountName" required/>
          </b-field>
          <b-field horizontal label="IBAN veya Papara Hesap Numarası">
            <b-input size="is-small" v-model="form.iban" placeholder="IBAN" name="iban" required/>
          </b-field>
          <b-field horizontal label="Açıklama">
            <b-input size="is-small" v-model="form.description" placeholder="Açıklama" name="description" required/>
          </b-field>
          <b-field horizontal label="Durum" v-if="!$route.params.id && !$store.state.user.siteId">
            <b-select size="is-small" v-model="form.status">
              <option :value="key" v-for="(item, key) in enums.transactionTypes">{{ item }}</option>
            </b-select>
          </b-field>
          <hr/>
          <div class="is-flex is-justify-content-end is-flex-grow-1">
            <div class="control mr-2">
              <b-button native-type="submit" type="is-primary" size="is-small">
                {{ $route.params.id ? 'Güncelle' : 'Ekle' }}
              </b-button>
            </div>
            <div class="control">
              <b-button type="is-primary is-outlined" size="is-small" @click="$router.go(-1)">Geri Dön</b-button>
            </div>
          </div>
        </form>
      </card-component>
    </section>
  </div>
</template>

<script>
import mapValues from 'lodash/mapValues'
import CardComponent from '@/components/CardComponent'
import { TransactionService as Service, BankService, SiteService } from '@/services'

export default {
  name: 'WithdrawCreateOrUpdate',
  components: { CardComponent },
  data () {
    return {
      isLoading: false,
      form: {
        siteId: null,
        bankId: null,
        bankAccountId: null,
        status: 'PENDING',
        statusReason: null,
        total: null,
        userId: null,
        name: null,
        username: null,
        processId: null,
        accountName: null,
        iban: null,
      },
      banks: [],
      sites: [],
    }
  },
  mounted () {
    this.getBanks()

    if (!this.$store.state.user.siteId) this.getSites()
    if (this.$route.params.id) this.getData()
  },
  computed: {
    getBankAccounts () {
      return this.banks.find(item => item.uuid === this.form.bankId).accounts
    }
  },
  methods: {
    getBanks () {
      BankService.all().then(res => this.banks = res.data)
    },
    getSites () {
      SiteService.all().then(res => this.sites = res.data)
    },
    getData () {
      Service.edit(this.$route.params.id).then(res => {
        this.form = res.data
      })
    },
    save () {
      Service[this.$route.params.id ? 'update' : 'createWithdraw'](this.form)
          .then(res => {
            this.$buefy.toast.open({
              message: this.$route.params.id ? 'Güncellendi' : 'Eklendi',
              type: 'is-success'
            })
            this.reset()
            this.$router.go(-1)
          })
          .catch(err => this.$buefy.toast.open({ message: err.message, position: 'is-top-right', type: 'is-danger' }))
    },
    reset () {
      this.form = mapValues(this.form, (item) => {
        if (item && typeof item === 'object') {
          return []
        }
        return null
      })
    }
  }
}
</script>
